<template>
  <div class="bf-content">
    <div 
      class="bf-content-menu-button"
      @click="showMenu = !showMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div 
      class="bf-content-menu-shadow"
      @click="showMenu = !showMenu"
      v-bind:class="{ 'is-visible': showMenu }"/>
    <div 
      class="bf-content-menu-content"
      v-bind:class="{ 'is-visible': showMenu }">
      <ul>
        <li><a href="#" @click="showMenu = false" v-scroll-to="'#what_i_do'" v-html="$t('menu-about')"></a></li>
        <li><a href="#" @click="showMenu = false" v-scroll-to="'#experience'" v-html="$t('menu-experience')"></a></li>
        <li><a href="#" @click="showMenu = false" v-scroll-to="'#contact'" v-html="$t('menu-contact')"></a></li>
      </ul>
    </div>
    <header class="bf-content-hello" id="hello">
      <img src="../assets/portrait.png" alt="">
      <div class="bf-content-hello-press">
        <h1 class="bf-content-hello-press-title">👋 Hey, Breixo here, I am a Senior Product designer and researcher.</h1>
        <p>I love human-centric design, technical challenges, international teams, and good wine.</p>
      </div>
    </header>
    <section class="bf-content-about" id="about">
      <h3 id="what_i_do" ref="Portfolio">What I do best is</h3>
      <ul>
        <li><span class="simbolo">👌</span><p>User research and strategy: building a roadmap through workshops, interviews, and testing.</p></li>
        <li><span class="simbolo">👌</span><p>Interaction design: work collectively on features from conception to shipping and beyond.</p></li>
        <li><span class="simbolo">👌</span><p>DesignOps: building realistic and scalable procedures and artifacts.</p></li>
      </ul>
      <h1 class="bf-content-portfolio-title">Recent works</h1>
      <ul class="bf-showcase">
        <li><router-link to="Veepee"><img src="../assets/veepee.png" alt="Veepee"><span>Strategy and DesignOps<br>@Veepee</span></router-link></li>
        <li><router-link  to="Cycloid"><img src="../assets/cycloid.png" alt="Veepee"><span>Product Design<br>@Cycloid</span></router-link></li>
        <li><router-link  to="Lulu"><img src="../assets/lulu.png" alt="Veepee"><span>User Research<br>@Lulu dans ma rue</span></router-link></li>
      </ul>
    </section>
    <section class="bf-content-cv" id="experience">
      <div class="bf-content-cv-title">
        <h2>Experience</h2>
        <a target="_tab" href="CV_Breixo_Fernandez.pdf"><img src="../assets/pdf.png"  alt=""></a>
      </div>

      <ul class="bf-content-talks">
        <li>
          <img src="../assets/veepee.png" alt="">
          <div>
            <p><b>Veepee - Senior Product Designer</b><span class="period">Remote 2021 - 2023</span></p>
            <p class="tags">User Research, DesignOps, Product Design, Road Map</p>
          </div>  
        </li>
        <li>
          <img src="../assets/cycloid.png" alt="">
          <div>
            <p><b>Cycloid - Lead Designer</b><span class="period">Remote 2018 - 2020</span></p>
            <p class="tags">Product Design, Design Thinking, Interaction Design, Prototyping</p>
          </div>
        </li>
        <li>
          <img src="../assets/lulu.png" alt="">
          <div>
            <p><b>Lulu dans ma rue - Designer & Researcher</b> <span class="period">Paris 2016 - 2017</span></p>
            <p class="tags">User Research, Design Thinking, Interaction design, Prototyping, Architecture</p>
          </div>
        </li>
        <li>
          <img src="../assets/ippon.jpg" alt="">
          <div>
            <p><b>Ippon - UX Designer / Front dev</b> <span class="period">Paris 2012 - 2015</span></p>
            <p class="tags">Interaction design, Prototyping, Front End Dev</p>
            <img src="../assets/chanel.jpg" alt="" class="mini">
            <img src="../assets/sg.jpg" alt="" class="mini">
            <img src="../assets/unibail.png" alt="" class="mini">
            <img src="../assets/axa.png" alt="" class="mini">
          </div>
        </li>
        <li>
          <img src="../assets/sopra.jpeg" alt="">
          <div>
            <p><b>Sopra - J2EE Dev</b> <span class="period">Madrid 2010 - 2012</span></p>
            <p class="tags">J2EE development, Management</p>
            <img src="../assets/sante.jpg" alt="" class="mini">
            <img src="../assets/sg.jpg" alt="" class="mini">
          </div>
        </li>
      </ul>

      <br>
      <h3>Teaching</h3>
      <br>

      <ul class="bf-content-talks">
        <li>
          <img src="../assets/hab.svg" alt="">
          <div>
            <p><b>Design for developers mini course</b><span class="period">September 2022 - Present</span></p>
            <p>HackABoss</p>
          </div>
        </li>
        <li>
          <img src="../assets/oc.webp" alt="">
          <div>
          <p><b>Freelance Advisor for UX Certification</b><span class="period">May 2022 - Present</span></p>
          <p>Open Classroom</p>
        </div>
        </li>
        <li>
          <img src="../assets/iron.png" alt="">
          <div>
          <p><b>Talk: Qualitative user research</b><span class="period">December 2021</span></p>
          <p>Ironhack</p>
        </div>
        </li>
        <li>
          <img src="../assets/malt.png" alt="">
          <div>
          <p><b>Talk: HOWTO Remote User Research</b><span class="period">October 2021</span></p>
          <p>Malt Academy</p>
        </div>
        </li>
        <li>
          <img src="../assets/sg.jpg" alt="">
          <div>
          <p><b>Talk: User Centered Design &amp; Responsive</b><span class="period">May 2015</span></p>
          <p>Fast IT Lab by Société Générale</p>
        </div>
        </li>
        <li>
          <img src="../assets/ippon.jpg" alt="">
        <div>
          <p><b>Talk: SASS &amp; SMACSS</b><span class="period">January 2015</span></p>
          <p>Ippevent</p>
        </div>
        </li>
      </ul>
    </section>
    <Language id="language"/>
    <hooper :settings="hooperSettingsReviews" class="hooperReviews">
          <slide>
            <img src="../assets/ingrid-.jpg" alt="">
            <a href="https://www.linkedin.com/in/ingrid-broussillon" target="_new" v-html="$t('reviews.ingridtitle')"></a>
            <p v-html="$t('reviews.ingrid')"></p>
          </slide>
          <slide>
            <img src="../assets/ben-.jpg" alt="">
            <a href="https://www.linkedin.com/in/benjamin-brial" target="_new" v-html="$t('reviews.bentitle')"></a>
            <p v-html="$t('reviews.ben')"></p>
          </slide>
          <slide>
            <img src="../assets/galicloud-.jpg" alt="">
            <a href="https://www.galicloud.com" target="_new" v-html="$t('reviews.galicloudtitle')"></a>
            <p v-html="$t('reviews.galicloud')"></p>
          </slide>
          <slide>
            <img src="../assets/charline-.jpg" alt="">
            <a href="https://www.linkedin.com/in/charline-moncoucut" target="_new" v-html="$t('reviews.charlinetitle')"></a>
            <p v-html="$t('reviews.charline')"></p>
          </slide>
          <slide>
            <img src="../assets/fabien-.jpg" alt="">
            <a href="https://www.linkedin.com/in/fabienvauchelles/" target="_new" v-html="$t('reviews.fabientitle')"></a>
            <p v-html="$t('reviews.fabien')"></p>
          </slide>
          <slide>
            <img src="../assets/louis-.jpg" alt="">
            <a href="https://www.linkedin.com/in/louisb-productdesigner/" target="_new" v-html="$t('reviews.louistitle')"></a>
            <p v-html="$t('reviews.louis')"></p>
          </slide>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
    <Contact id="contact"/>
    <footer>
      Breixo Fernández Product Design & Research {{new Date().getFullYear()}} ©
    </footer>
  </div>
</template>

<script>
import Contact from './contact.vue'
import Language from './language.vue'
import {
  Hooper,
  Slide,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'Content',
  components: {
    Contact,
    Language, 
    Hooper, 
    Slide,
    HooperPagination
  },
  data() {
    return {
      hash: this.$route.hash,
      showMenu: false,
      hooperSettingsReviews: {
        itemsToShow: 1,
        centerMode: false,
        autoPlay: false,
        playSpeed: 5000,
        infiniteScroll: true,
        breakpoints: {
          600: {
            centerMode: true,
            itemsToShow: 1
          },
          1000: {
            centerMode: true,
            itemsToShow: 3
          }
        }
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
          const refName = this.hash.replace('#', '')
          this.scrollToAnchorPoint(refName)
      }
    })
    if (localStorage.getItem("bf-selected_language")) {
      this.$root.$i18n.locale = localStorage.getItem("bf-selected_language")
    }
  },
   methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName]
      el.scrollIntoView()
    }
  }

}
</script>

<style lang="scss" scoped>
@import 'responsive';
header {
  padding-top: 108px !important;
}

header, section {
  margin: 6em 0;
}

h2 {
  font-size: 48px;
  margin: 0.83em 0;

  @include mq($from: mobile, $until: tablet) {
    font-size: 32px;
  }
}

p, li {
  font-size: 25px;

  @include mq($from: mobile, $until: tablet) {
    font-size: 20px;
  }
}

li {
  padding: 8px 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.period {
  font-variant: small-caps;
  margin-left: 24px;
  float: right;
  @include mq($from: mobile, $until: tablet) {
    min-width: 100%;
    float: left;
    display: block;
    margin-left: 0;
  }
}

.tags {
  font-style: italic;
  font-size: 18px;
  line-height: 1.8em;
}

.simbolo {
  font-size: 32px;
  padding-right: 20px;

  @include mq($from: mobile, $until: tablet) {
    font-size: 28px;
    padding-right: 12px;
  }
}

ul {
  padding-left: 36px;
  
  @include mq($from: mobile, $until: tablet) {
    padding-left: 0;
  }
}

a {
  color: #1B81A1;
  font-weight: 700;
  text-decoration: none;
  background-image: Radial-gradient(ellipse at bottom right, rgba(#1CBCED, 0.3), rgba(#1B81A1, 0.3));
  background-size: 0;
  background-repeat: no-repeat;
  display: inline;
  transition: 0.15s cubic-bezier(0.4, 0, 1, 1);
  
  &:hover {
    background-size: 100%;
    color: wheat;
  }
}

.bf-content-hello {
  padding-top: 48px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq($from: mobile, $until: tablet) {
    flex-direction: column;
    text-align: center;
    padding-bottom: 0;
  }

  img { 
    max-width: 228px;
    margin-right: 16px;
    @include mq($from: mobile, $until: tablet) {
      margin: 0;
      max-width: 160px;
    }
  }

  &-press {
    padding: 0 12px;
  }

  &-press-title {
    font-size: 30px;
    margin-top: 82px;
    margin-bottom: 24px;

    @include mq($from: mobile, $until: tablet) {
      font-size: 22px;
      margin-top: 28px;
    }
  }

  &-press-greeting {
    max-width: 400px;

    @include mq($from: mobile, $until: tablet) {
      max-width: 280px;
      margin: 0 auto;
      font-size: 20px;
    }
  }
}

.bf-content-menu-button {
  position: fixed;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: Radial-gradient(ellipse at bottom right, #1CBCED, #1B81A1);
  z-index: 1000;
  cursor: pointer;

  span {
    background: white;
    position: block;
    height: 3px;
    margin: 2px 0;
    border-radius: 1px;
    width: 70%;
  }

}

.bf-content-menu-shadow {
  display: none;

  &.is-visible {
    display: block;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.2);
  }
}

.bf-content-menu-content {
  z-index: 1001;
  box-shadow: 0 1px 5px 0 #eaf6ff;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -170px;
  margin-top: -1px;
  transition: margin-left 0.2s ease-out;

  &-lang {
    margin-top: 12px;
  }

  &.is-visible {
    margin-left: -1px;
  }

  img {
    width: 120px;
    padding: 10px;
    padding-bottom: 0;
    background-color: white;
  }

  ul {
    width: 140px;
    background-color: white;
    padding: 0 10px;
    margin: 0;
    margin-top: -2px;
    a {
      text-align: center;
      display: block;
      width: 100%;

      &:hover {
        color: grey;
      }
    }
  }
}

.bf-content-hello, .bf-content-about, .bf-content-cv, #contact {
  max-width: 998px;
  margin: 0 auto;
  padding-top: 32px;

  @include mq($from: mobile, $until: desktop-high) {
    padding: 12px;
  }
}

#what_i_do {
  padding-top: 40px;
}

.bf-content-portfolio-title {
  text-align: center;
  font-size: 32px;
  margin: 96px 0 48px;
}

.bf-showcase {
  padding: 0;
  margin-bottom: 96px;
  display: flex;
  justify-content: space-between;

  li {
    width: 31%;
  }

  a {
    background: Radial-gradient(ellipse at bottom right, #1CBCED 1%, #1B81A1);
    color: #ffedca;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-family: "PT Serif Caption", sans-serif;

    &:hover {
      background: Radial-gradient(ellipse at bottom right, #1CBCED 62%, #1B81A1);
    }

  }
  img {
    max-height: 140px;
    max-width: 100%;
    padding: 5%;
    border-radius: 24px;
  }

  @include mq($from: mobile, $until: tablet) {
    flex-direction: column;

    li {
      width: 100%;
      margin-bottom: 16px;
    }

    a {
      width: 100%;
    }
  }
  

}

.bf-content-cv-title {
  padding-top: 32px;
  display: flex;
  align-items: center;
  a {
    margin-left: 32px;
  }
  img {
    width: 64px;
    height: 64px;
  }
}

.bf-content-talks {
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;  
  }
  div {
    width: 100%;
    padding-left: 24px;
  }
  img {
    width: 72px;
    border-radius: 3px;
    background: white;
  }
  p {
    margin: 0;
  }
  .mini {
    width: 52px;
    display: inline;
    margin-top: 16px;
    margin-right: 8px;
  }
}

.bf-content-cv-screenshots {
  margin-top: 10em !important;

  h1, h3, p {
    text-align: center;
  }

  h1 {
    font-size: 48px;
  }

  h3 {
    margin-top: 86px;
  }

  p {
    max-width: 656px;
    margin: 16px auto 28px;
  }
  
  &-ul li {
    text-align: center;
    display: block;
  }

  
}

#contact {
  margin-bottom: 80px;
}
</style>

<!-- timeline and hoper styling -->
<style lang="scss">
@import 'responsive';

.experience-timeline-mini {
  display: block;
  img {
    width: 64px;
    margin: 20px 20px 0 0;
    border-radius: 2px;
  }
}

.hooper {
  height: 400px;
  margin: 0 auto 32px !important;
  user-select: none;

  @include mq($from: mobile, $until: tablet) {
    width: 100%;
    height: auto;
    margin: 0 !important;
  }
}

.hooper-indicator:hover, .hooper-indicator.is-active {
  background-color: #1B81A1;
}

.hooper-slide {
  display: flex;
  flex-direction: column;
}

.hooper-slide img {
  max-height: 82%;
  max-width: 82%;
  display: block;
  margin: 0 auto;

  @include mq($from: mobile, $until: tablet) {
    width: 100%;
    height: auto;
  }
}

.hoper-slide p {
  height: 10%;
  text-align: center;
}

.hooper-navigation {
  @include mq($from: mobile, $until: tablet) {
    display: none;
  }
}

.hooper svg.icon.icon-arrowRight, .hooper svg.icon.icon-arrowLeft {
    width: 48px !important;
    height: 48px !important;
    fill: wheat;
}

.bf-content-reviews {
  h1 {
    text-align: center;
    @include mq($from: mobile, $until: tablet) {
      max-width: 280px;
      margin: 1em auto;
    }
  }
}

.hooperReviews {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 390px;
  width: 1160px;
  max-width: 100%;
  margin: 0 auto 28px !important;

  .hooper-slide {
    padding: 20px;
  }

  img {
    width: 50%;
    max-width: 72px;
    margin: 20px auto;
    border-radius: 50%;
  }

  a {
    margin-bottom: 12px;
    font-size: 24px;
    text-align: center;
    font-family: ''
  }

  a::before {
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(../assets/linkedin.png);
  }

  p {
    height: 60%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
  }
}

footer {
  display: block;
  text-align: center;
  background-color: black;
  margin-top: 30vh;
  padding: 30px;
}
</style>

<i18n>
{
  "en": {
    "menu-about": "Portfolio",
    "menu-experience": "Experience",
    "menu-contact": "Contact",
    "hello-press-sub": "Hello! Breixo here, I'm a researcher and product designer with a technical background",
    "about": {
      "heading": "Experience",
      "p1": "I started up as a developer, then balanced front end and UX and ended up focusing on design and research. I like the human side of technology :)",
      "p2": "I understand product design as a balance between priorities and capabilities, backed in research, and collaborative.",
      "p3": "I live in Galicia, north west of Spain. Like most people, I like traveling, cooking, and reading."
    },
    "whatIDo": {
      "heading": "What I do",
      "li1": "User research: mostly qualitative, workshops, interviews, and testing.",
      "li2": "DesignOps: realistic and scalable approaches to use a design system."
    },
    "screenshots": {
      "portfolioButton": "Check my portfolio"
    },
    "reviews": {
      "galicloudtitle": "Galicloud, Web agency",
      "galicloud": "We worked with Breixo on the development of Cycloid’s website. Breixo was a great Scrum Master quickly adopting the tools we proposed to use and solving our different needs during the project. He was useful easing communication with the team, and also handling priority of the various tasks and reviewing their definition. We’d repeat gladly.",
      "bentitle": "Benjamin, Founder @ Cycloid",
      "ben": "In a highly technical product, Breixo promoted initiatives to introduce a user driven approach wich lead to better UX and ease of adoption of our framework. He can collaborate with various profiles, more or less technical.",
      "charlinetitle": "Charline, UI & front @ Cycloid",
      "charline": "I’ve worked together for two years as UI and UX. Under his promotion we have managed to implement design thinking approaches, and improve visual coherence of our product. It is easy and pleasant to work with Breixo, his social character and his whil for continuous improvement are precious qualities for a designer.",
      "ingridtitle": "Ingrid, Product owner @ Société Générale",
      "ingrid": "I’ve worked with Breixo on the redesign of different communication apps. First designer of the team, he evangelized UX and proposed ways to integrate it in our workflows. We defined mobile friendly, reusable interaction patterns with the goal of industrializing future apps and products adaptation.",
      "fabientitle": "Fabien, data architect @ Axa",
      "fabien": "I've worked with Breixo at AXA and he's a designer who likes to design well-structured interfaces. He knows more than UX/UI and always finds the way to move on the frontend dev, to have a global overview!",
      "louistitle": "Louis, Product designer @ Veepee",
      "louis": "He is one of the best designers I worked with. He is solution oriented, very good technically and listening to users. He speaks French, English and Spanish with ease. Sense of humor 5 ⭐ a super compañero!"
    }
  }
}
</i18n>