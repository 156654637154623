<template>
  <div class="bf-contact">
    <br>
    <h1>Contact</h1>
    <div class="bf-contact-content">
      <div>
        <ul>
          <li>Looking for a new member of the crew?</li>
          <li>A short collaboration?</li>
          <li>Are you Charlize Theron and you dream of quitting a life of superficial luxury that doesn't satisfy you to live in the countryside with the future love of your life?</li>
        </ul>
      </div>
      <div>
        <div class="bf-contact-act">
          <div class="bf-contact-mail">
            <a 
              class="bf-contact-link"
              href="mailto:hello@brei.pro"><b>hello@brei.pro</b></a>
            <img v-if="!copied" @click.stop="copy()" src="../assets/copy.svg" alt="">
            <span v-if="copied">👌</span>
          </div>
          <br>
          <div class="bf-contact-icons">
            <a href="https://www.linkedin.com/in/breixo-fl/" target="_new"><img src="../assets/linkedin.svg" alt=""></a>
            <a href="https://www.malt.es/profile/breixofl" target="_new"><img src="../assets/malt.svg" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data: function () {
    return {
      copied: false
    }
  },
  methods: {
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea")
      textArea.value = text
      
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    
    copy(text = "hello@brei.pro") {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!')
      }, function(err) {
        console.error('Async: Could not copy text: ', err)
      })
      this.copied = true
      setTimeout(()=>{
        this.copied = false
      },1200);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'responsive';

a {
  color: #1B81A1;
  font-weight: 700;
  text-decoration: none;
  background-image: Radial-gradient(ellipse at bottom right, rgba(#1CBCED, 0.3), rgba(#1B81A1, 0.3));
  background-size: 0;
  background-repeat: no-repeat;
  display: flex;
  transition: 0.15s cubic-bezier(0.4, 0, 1, 1);
  
  &:hover {
    background-size: 100%;
    color: wheat;
  }

  img {
    width: 24px;
    margin-left: 22px;
  }
}

ul {
  @include mq($from: mobile, $until: tablet) {
    padding-left: 24px;
  }
}

.bf-contact {
  display: flex;
  flex-direction: column;
  font-size: 22px;

  @include mq($from: mobile, $until: tablet) {
    font-size: 20px;
  }
}

.bf-contact-content {
  display: flex;
  align-items: center;
  flex: 50%;

  @include mq($from: mobile, $until: tablet) {
    width: 100%;
    padding-right: 0%;
    flex-direction: column;
  }

  div {
    flex: 50%;
  }

  ul {
    padding: 0;
  }

  .bf-contact-act {
    display: flex;
    flex-direction: column;
    align-items: center;


    @include mq($from: mobile, $until: tablet) {
      margin-top: 30px;  
    }
    
    & > .bf-contact-mail {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      img, span {
        width: 28px;
        cursor: pointer;
        margin-left: 12px;
      }
    }
  }
}


.bf-contact-button {
  display: inline-block;
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  background: Radial-gradient(ellipse at bottom right, #1CBCED, #1B81A1);
  color: wheat;
  border-radius: 6px;
  min-width: 200px;
  font-size: 38px;
	cursor: pointer;
}

.bf-contact-link {
  font-size: 32px;
  font-weight: 100;
}

.bf-contact-icons {
  display: flex;
  margin-top: 16px;

  & img {
    width: 48px;
    margin: 8px 12px;
  }

  @include mq($from: mobile, $until: tablet) {
    justify-content: center;
  }
}

.contact-body {
    background-size: cover;
    background-attachment: fixed;
    height:700px;
    max-width: 100%;
}

.contact-body * {
  font-family: "PT Sans Narrow", sans-serif;
}

.title{
    margin: 0 auto;
    width: 50%;
    text-align: center;
    padding-bottom: 10px;
    font-size: 42px;
    color: rgb(252, 252, 252);
  }

  #title-Tag-Line{
      font-size: 20px;
  }
/* This will center the form */
  .wrapper {
    width: 100%;
    height: 50%;
    margin: 5% auto 0 auto; 
  }

/* form animation starts */
  .form, .contact-sended {
    background: #EBEBEB;
    text-align: center; 
    border-radius: 2px;  
    width: 94%; 
    margin: 16px auto 0 auto;
    padding: 10px 10px;

    @include mq($from: mobile, $until: tablet) {
      width: initial;
      margin: 0;
    }
  }

  .contact-sended{
    img {
      width: 50%;
    }

    p {
      color: black;
      font-size: 28px;
      padding: 0 22px;
      margin: 10px 0;
    }

    span {
      font-size: 72px;
      color: black;
    }
  }

.error-message {
  text-align: left;
  color: red;
  font-size: 14px;
  padding: 0 24px;
  margin: 0;
}

form h4 {
  color: black;
  font-size: 1.5em;
}

.name { 
    background-color: #e45a84;
    border-bottom: 5px solid #B2175F;
    color: white;
    width:300px;
    font-size: 22px;
    max-width: 80%;
  }

  ::-webkit-input-placeholder{
      color: white;
      font-size: 20px;
  }
  
  .email {
    background-color: #583c87;  
    border-bottom: 5px solid #502789;
    width:300px;
    color: white;
    font-size: 22px;
    max-width: 80%;
  }
  
  .message {   
    background-color: #61152a;
    border-bottom: 5px solid rgb(195, 35, 17);
    overflow: hidden;
    height: 240px;  
    width:300px;
    color: white;
    font-size: 22px;
    max-width: 80%;
  }

  .entry{
    display: block;
    margin: 12px auto;
    padding: 10px;
    border-radius: 5px;
    border: none;
    transition: all 0.5s ease 0s;
    box-shadow: 0px 0px 25px 2px #aaa;
  }

  .is-errored {
    border: 4px solid red;
  }


.submit{
  background: Radial-gradient(ellipse at bottom right, #1CBCED, #1B81A1);  
  border-radius:5px;
  padding:10px;
  width:150px;
  color: white;
  border: none;
  border-bottom: 5px solid #1B81A1;
  font-size: 22px;
  cursor: pointer;
  
  img {
    width: 22px;
  }
}



@keyframes bounce{
  0%{
    
  }
  50%{
    transform: translate(0, 8px);
  }

}
@keyframes shadow{
  0%{
    
  }
  50%{
    opacity: 0.8;
    width: 50%;
  }
}

</style>
<i18n>
{
  "en": {
    "heading": "Contact",
    "li1Link": "Does what I propose",
    "li1": "seem interesting?",
    "li2Link": "Does what I look for",
    "li2": "fit your needs?",
    "li3": "Looking for a new member of the crew?",
    "li4": "A one-shot?",
    "li5": "Are you Charlize Theron and you dream of quitting a life of superficial luxury that doesn't satisfy you to live in the countryside with the future love of your life?",
    "continue": "Write me!",
    "name": "Name *",
    "email": "Email *",
    "message": "Your message *",
    "nameMandatory": "Your name is mandatory",
    "emailError": "This doesn't seem a valid email",
    "nameMandatory": "A message is mandatory",
    "submit": "Send",
    "ok": "Yay! I'll get back to you as soon as possible",
    "ko": "Oh, crap... something went wrong",
    "retry": "Retry"
  }
}
</i18n>