<template>
  <div class="bf-pf">
    <router-link :to="{ name: 'Sitio', hash: 'Portfolio' }" class="bf-pf-close">
      <img src="../assets/close.svg" alt="Close">
    </router-link>
    <div class="bf-pf-header">
      <img src="../assets/lulu.png" alt="Veepee">
      <div>
        <h1>Lulu dans ma rue</h1>
        <span>User Research for a social startup backed by the city of Paris.</span>
      </div>
    </div>
    <div class="bf-pf-body">
      <section class="bf-pf-context">
        <p>Founded by the social entrepreneur behind Emmaüs Défi, Lulu dans ma rue connects neighbors that need help. Lulus help you install a printer, clean your place, or bring a red rose and a croissant to your lover.</p> 
        <p>We created a platform, but also physical kiosks  different Paris districts so that people less familiar with technology were still able to use or provide our services.</p>
        <br>
        <img src="../assets/lulu/lulu.png" alt="The Place">
      </section>
      <section>
        <h1>Challenges</h1>
        <p>Lulu dans ma rue wanted to provide a human, neighbor-like experience. We defined and shipped the different systems needed to handle it, working extensively with customers and users.</p>
        <ul>
          <li><span class="simbolo">🔥</span><p><strong>Everything to be created:</strong> When I arrived we managed our activity with pen, paper, and phone calls. It was great for our customers and users but a lot of work for us internally.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Diverse users with different goals and needs:</strong> we wanted to include people with very different backgrounds, demographics, and knowledge of technology.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>A bunch of legal constraints:</strong> French work laws are quite protective and restrictive. We needed to ensure the legality of our actions in a fast-paced environment.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Need to escale activity:</strong> Lulu dans ma rue was meant to work in a franchise manner. This made our ability to speed-up and industrialize things a key to success.</p></li>
        </ul>
      </section>
      <section>
        <h1>Approach</h1>
        
        <ul class="topal">
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Research:</strong></p>
              <p>Physical implantation through kiosks was very important for us: it made our service accessible to people not comfortable with technology, and it was the perfect showcase.</p>
              <p>I worked a lot on the kiosk in the first moments. It was fun to open at nine o'clock. We always had some Lulus popping in, some people curious about our activity, and tourists thinking we were a tourist office, ...</p>
              <p>Being in the real environment for hours was of great help! We started working with physical forms for customers' requests, improved them, and used them as a starting point for the digital experience.</p>
              <p>I also did a lot of Guerrilla testing with potential customers. 5-10 min interviews in exchange for some hot beverage. Those short conversations helped us validate or modify our strategy.</p>

              <img class="fullsizeimg" src="../assets/lulu/lu1.png" alt="">
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Ideation Phase:</strong></p>
              <p>Lulu dans ma rue has partnerships with social institutions: we collaborated with social workers and NGOs to ensure our approach was beneficial for the Lulus, the people providing different services.</p>
              <p>We put a lot of effort into creating a neighbor-like experience. When we started, customers connecting to our app only had a text area, they described what they wanted, and we managed everything to bring them the right Lulu at the right time. It was great and personal, but very hard to scale.</p>
              <p>We conceived and slowly moved to a more deterministic approach, in which customers would categorize their needs in the app and provide details about the help they needed, which lead to a much more automated process and made us capable of assigning jobs to Lulus semi-automatically.</p>
              <p>In parallel with this, we worked extensively with the Lulus to make sure we provided a kind, human-faced experience for them. For the ones less comfortable with technology, we ideated ways to keep them included, by providing simple UI and user flows and supporting and training them.</p>
              <img src="../assets/lulu/lu2.png" alt="">
              <li class="recipe">
            <span class="simbolo"> </span>
            <div>
              <div class="bf-pf-button-block">
                💡💡💡
                <button class="bf-pf-button" 
                @click="expand()">{{expanded ? 'Hide':'View'}} Co-design workshop recipe</button>
                💡💡💡
              </div>
              <div class="bf-recipe-content" :class="{ expanded: expanded }">
                <h3>🔪 Ingredients</h3>
                <ul>
                  <li>- Motivated participants</li>
                  <li>- Simple and realistic goals</li>
                  <li>- Dynamics to make people comfortable and to push a creative mood</li>
                  <li>- Simple artifacts to concentrate on what matters</li>
                </ul>
                <p>Co-design workshops are always fun and help federate participants around the product. They work best if they mix participants with different skills and backgrounds. I like to create groups and make them work independently, then put everything together and let people get inspired by others' approaches. It needs a careful selection of people to make sure groups are balanced. Having some leadership in each group is nice, but it shouldn't be too strong. Carefully designed dynamics help avoid the one that speaks louder to end up owning the exercise. Sometimes you will want to minimize friction or confront visions. Sometimes you may want to encourage those.</p>
                <p>It is always a good idea to simplify choices to avoid people focusing on shape over function. You expect to have good ideas from a co-design exercise, not aesthetics or disruptive UX/UI. If conducted in the same physical place, I always prefer using pen and paper and usually prepare a set of components ready to use. Creating new ones is allowed, but having an example helps participants grasp the right level of detail we are expecting from them. If performed remotely, the difficulty of handing over the prototyping tool needs consideration. We can work things up to streamline work, provide templates or pre-built components, or assign people with the required skills to each group. In any case, it is a good idea to keep things simple, to work at a lo-fi level, and to make sure we walk with groups closely to identify divergence and set focus on what matters. This kind of exercise needs a lot of guidance to work well, and it is always good to have some support from other designers, which you should brief before the workshop.</p>
                <br>
                <h3>🍳 Cooking it</h3>
                <p>It is crucial to create an ambient of collaboration and to make everyone feel comfortable. I start with some icebreakers that help put everyone at the same level regardless of their role and importance. Then we can balance exercises to perform in groups with moments in which we bring everything together. Cross-group collaboration is always interesting after a while. Assign some visitors to participate in other groups, and they will provide good feedback and get fresh ideas or approaches to share with theirs.</p>
                <p>While conducting the exercises, the moderator should ensure that everyone participates in the process. If someone takes too much room, you can limit this by imposing some restrictions, like making someone else be in charge of the craft or by defining an interaction pattern that limits individual contribution. Having different people presenting things, or leading particular aspects of the session, also helps to make the design process more collective.</p>
                <p>Different levels of detail may coexist during the exercise. We can start with strategy and a bird view on the things to design, to jump into detail later. It is desirable to let detailed work challenge the vision, so I advocate for reviewing strategic statements again once people create a concrete design. Confronting and aligning different points of view is one of the main strengths of this technique, so always plan some time in the end for people to express themselves about the exercise, relevant discussions that happened, or things that surprised them.</p>
                <br>
                <h3>🧁 Mise en place</h3>
                <p>Co-design is collective, so its outcome should also be. Let people vote which are the most relevant outcomes of the exercise, and give place for opinions and reflections on the subject. Very often, the most valuable output of a co-design session is not the design itself but a better understanding of other people's context. People with different needs empathize with others' vision, and organizational improvements arise.</p>
                <p>Restitution of a co-design workshop can also be collective: make sure to present results to participants and encourage them to provide feedback. A whiteboard and the possibility to comment on it is a simple yet efficient way to make the subject live longer than the exercise itself, and it also helps to create coherency across different workshops over time.</p>
              </div>
            </div>
          </li>
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Product design:</strong></p>
              <p>Discovery and Ideation lead us to identify several axes to move on. For some, we decided to implement features in our internal tools. For others, we relied on third-party solutions.</p>
              <br>
              <p><strong>Customers space</strong></p>
              <p>Letting customers describe what they wanted was a great experience, but it implied a lot of work for us.</p>
              <p>We conceived multistep flows to categorize effectively the different services we provided while keeping the process simple and intuitive.</p>
              <p>To keep things as human as possible, we oriented it conversationally, making Fred, our mascot, ask customers the right questions to make sure we had all the information to propose it to the right Lulu.</p>
              <p>We built this interface to be easily extendable and documented rules to create new elements if needed. This work streamlined the creation of new services in the customer space, as most of their configuration was covered by the building blocks and guidelines provided by the Design team.</p>
              <img src="../assets/lulu/lu3.png" alt="">
              <br><br><br>
              <p><strong>Lulu App</strong></p>
              <p>Some Lulus were people with economic difficulties, often with a poor understanding of technology. Others were interested in the idea of retrieving the neighborhood cohesion in the city of Paris.</p>
              <p>After some time handling our activity with SMS and phone calls, we decided to build our platform for assigning services. Our main concerns were not to leave Lulus behind because of technology, and to keep a good balance between reactivity and fairness on the assignation of work.</p>
              <p>We ended up conceiving a very simplistic interface, that vehiculated with colors the different steps of our workflow: a big green button to accept and a big red button to refuse a job. We carefully designed messaging so that people weren't frustrated receiving a lot of proposals but not being quick enough. We also provided shortcuts and advanced UI interactions for power users.</p>
              <p>Most of the problems we faced were solved by organizing ourselves with the Lulus. Some were very proactive and had great ideas. Performing workshops and co-designing sessions with them helped us grasp the particularities of each area of services, and adapt our strategy.</p>
              <img src="../assets/lulu/lu4.png" alt="">
            </div>
          </li>
        </ul>
      </section>
      <section>
        <h1>Outcomes</h1>
        <ul>
          <li><span class="simbolo">❤️</span><p><strong>Confidence to transition to a digital experience:</strong> we were reluctant to move from manual handling of requests into a more automated experience, we didn't want to lose the human touch. By performing extensive research, we managed to identify the human interactions that mattered to our users and keep a personal treatment for some cases, automating the rest. We managed activity with less effort, still providing a good experience for users.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>Inclusion of all kinds of people:</strong> research work helped us identify the blockers for people not comfortable with technology to become a user or a Lulu. Designing our products with these blockers in mind resulted in very accessible tools, understandable for people without a technological background.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>A coherent language for activities definition:</strong> while moving to a more deterministic approach, in which users would provide relevant information for services through forms, we created and documented a UI set streamlining the creation of new services. Following these guidelines, a developer could, in most cases, add a new service to the catalog autonomously.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>A federated community:</strong> by including Lulus, our service providers, in the conception and design of the tools, we created a proactive and engaged community of users, whiling to participate in workshops or interviews, and very active in the evolution of our business and products.</p></li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lulu',
  mounted () {
    window.scrollTo(0, 0)
  },
  data: function () {
    return {
      expanded: false
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
    }
  }
}
</script>