import Vue from 'vue'
import Router from 'vue-router'
import Content from './components/content.vue'
import Veepee from './components/veepee.vue'
import Cycloid from './components/cycloid.vue'
import Lulu from './components/lulu.vue'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Sitio',
      component: Content
    },
    {
      path: '/Veepee',
      name: 'Veepee',
      component: Veepee
    },
    {
      path: '/Cycloid',
      name: 'Cycloid',
      component: Cycloid
    },
    {
      path: '/Lulu',
      name: 'Lulu',
      component: Lulu
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
})



export default router