<template>
  <div class="bf-pf">
    <router-link :to="{ name: 'Sitio', hash: 'Portfolio' }" class="bf-pf-close">
      <img src="../assets/close.svg" alt="Close">
    </router-link>
    <div class="bf-pf-header">
      <img src="../assets/veepee.png" alt="Veepee">
      <div>
        <h1>Veepee</h1>
        <span>Strategy and DevOps for an e-commerce leader's new section.</span>
      </div>
    </div>
    <div class="bf-pf-body">
      <section class="bf-pf-context">
        <p>Veepee is a French retailer selling fashion, home appliances, sports equipment, technology, wines, entertainment tickets, music, etc</p> 
        <p>With a strategy based on diversification of activity, opening to new markets, and local heroes acquisitions, they aim to become a European-level leader in e-commerce. Their differentiators are partnerships with premium brands and exclusive opportunities for buyers.</p>
        <p>In 2020 Veepee opened The Place, a new section of the site where premium brands propose a permanent catalog of articles and bundles.</p>
        <img src="../assets/veepee/vpTP.png" alt="The Place">
      </section>
      <section>
        <h1>Challenges</h1>
        <p>Veepee wanted to provide a premium experience to brands selling in The Place, we worked on converging different products into a cohesive experience. This initiative was called Brand Portal.</p>
        <ul>
          <li><span class="simbolo">🔥</span><p><strong>Need to unify processes:</strong> activity was handled without common directives. Opening of new markets was an ad-hoc process, difficulting planning and visibility.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Diverse and unconnected tools:</strong> organic growth and adquisitions resulted in a myriad of unconnected system handling activity. Different tools for different markets, lack of an overall vision.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Lack of common visual language:</strong> a first draft of a Design System was defined but abandoned. Every tool had different look and feel and interaction patterns.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Painful brands' onboarding:</strong> integration of new brands with different needs where handled with a case-by-case approach. The process was time-consuming and lacked of effective monitoring.</p></li>
        </ul>
      </section>
      <section>
        <h1>Approach</h1>
        
        <ul class="topal">
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Discovery Phase</strong></p>
              <p>I interviewed internal and external stakeholders: business developers, technical support, financial stakeholders, and brand representatives from companies such as Boulanger, Monoprix, or Ubaldi.</p>
              <p>I traced this at different levels, from detailed workflows to executive resumes. Insights were presented to product leads, with proposals to move on with them.</p>
              <p>The main bottleneck of our experience was the onboarding of new brands. I worked closely with Support, Business developers, and Sales teams to understand those processes and identify pains and gains.</p>
              <img src="../assets/veepee/vp1.png" alt="">
            </div>
          </li>
          <li class="recipe">
            <span class="simbolo"> </span>
            <div>
              <div class="bf-pf-button-block">
                💡💡💡
                <button class="bf-pf-button" 
                @click="expand()">{{expanded ? 'Hide':'View'}} Interview recipe </button>
                💡💡💡
              </div>
              <div class="bf-recipe-content" :class="{ expanded: expanded }">
                <h3>🔪 Ingredients</h3>
                <ul>
                  <li>- Context on the person(s) you are interviewing</li>
                  <li>- A fair understanding of the subject</li>
                  <li>- Clearly defined goal(s)</li>
                  <li>- A script to guide the interview</li>
                  <li>- Tools, prototypes</li>
                </ul>
                <p>Traditionally in user research, we establish a hypothesis for an experiment or interview and try to validate it as deterministically as possible. I find this a bit tricky for qualitative research, I prefer to define a set of focus points based on the goals established for the study. We have some things we can not miss, some things it would be nice to have an opinion on and trends that may group several aspects of the subject we want to study.</p>
                <p>I organize the focus points relevant to the study spacially to use them as a checklist, quick memo, and a placeholder for notes and comments during the interview. I used to do this by hand, but now I usually create a board with Miro or a similar tool.</p>
                <p>Having a template helps me analyze and summarize insights at the end, but it also helps identify new focus points during the sessions. The script often evolves to include relevant things we may not have considered at the beginning of the study.</p>      
                <br>
                <h3>🍳 Cooking it</h3>
                <p>Unuseful interviews are the ones in which the interviewee doesn't want to be there, so it is crucial to choose interviewees carefully.</p>
                <p>I introduce participants to the goals of the interview and communicate those honestly at any moment. Not surprisingly, making people active contributors to a cause and providing feedback on expectations works better than just asking questions for which our interviewee may not even see a connection.</p>
                <p>Interviews need structured moments, in which we may sort cards, play some routine or test a prototype, but also room for free talk, for participants to express themselves. It is good to structure things to keep participants' attention high while being flexible enough to make them comfortable and derive into other subjects.</p>
                <br>
                <h3>🧁 Mise en place</h3>
                <p>Restitution of research is a vast subject with a lot to do. It is nice to keep raw information, but most people will want simplified views on a knowledge base. I like to create an executive report so everybody can get an idea of the thing in a glimpse. It includes a list of insights backed by quotations or other concrete results, particular interviewees' realities relevant to the goals, and a list of proposals to move forward with the subject if pertinent.</p>
              </div>
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Ideation Phase</strong></p>
              <p>We identified technical onboardings as the area in which improvements would provide more value.</p>
              <p>Brand Support teams had a great experience and insights into brands' needs, pains, expectations, and desires. I organized a series of workshops with support leads and workers, exploring their activity and finding improvements.</p>
              <p>We simplified workflows, identified and categorized their activity to be more data-centric, and conceived efficient approaches: features, third-party software, organizational changes, etc. The goal was to provide a simple experience for brands and support while granting overall visibility for sales and decision-makers.</p>
              <img src="../assets/veepee/vp2.png" alt="">
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Product design</strong></p>
              <p>Discovery and Ideation lead us to identify several axes to move on. For some, we decided to implement features in our internal tools. For others, we relied on third-party solutions.</p>
              <br>
              <p><strong>Typologies</strong></p>
              <p>Brands selling with Veepee needed to define the different modalities for selling articles to customers.</p>
              <p>Together with technical teams, we defined a method for handling all the flexibility brands wanted while keeping internal consistency and traceability.</p>
              <p>We called this approach product typologies, it allows brands to create custom groups of products from their catalog and associate those to a given configuration.</p>
              <p>Most designs work better if tested, so we conducted two pilots with French retailers La Redoute and Boulanger. Those lead to validating our approach along with some improvements and modifications.</p>
              <img src="../assets/veepee/vp3.png" alt="">
              <br><br><br>
              <p><strong>The Place CRM</strong></p>
              <p>A CRM tool developed internally was used by onboarding teams to trace their activity, and by sales teams to consult advancement.</p>
              <p>We identified pains with this tool, lack of effective monitoring, lack of a data-centric approach, and inefficient communication.</p>
              <p>We worked with product owners, business process owners, and onboarding team representatives to identify and classify needs, and proposed a set of features, capabilities, and interactions.</p>
              <p>All this was synthesized and used to choose a commercial solution. When this happened, my work was used as a specification for adapting this solution to our needs.</p>
              <img src="../assets/veepee/vp4.png" alt="">
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>DesignOps :</strong></p>
              <p>I participated in the definition of Veepee's Design Systems. Along with other colleagues, we advocated asynchronous procedures, guidelines, and tools to manage them.</p>
              <p>With some devs we created ConcreteUI, the first implementation of the Brandsplace design system using React.</p>
              <p>We built a lean way to manage its evolution, documented it, and promoted it to become the official lib for the Brands portal.</p>
              <img src="../assets/veepee/vp5.png" alt="">
            </div>
          </li>
        </ul>
      </section>
      <section>
        <h1>Outcomes</h1>
        <ul>
          <li><span class="simbolo">❤️</span><p><strong>Roadmap and backlog well defined:</strong> together with Product leads we used insights from research to identify actions, and prioritized those through user testing. We ended up with a well-defined roadmap and a collectively owned backlog.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>Aligned knowledge, aligned vision:</strong> the topography I made of The Place provided an aligned vision for all team members. This knowledge, previously distributed and siloed was unified in a set of boards and presentations, making it accessible to everyone, and especially useful for newcomers' onboardings.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>A process for the specification of features:</strong> we worked with product teams to define an agile way of specifying features, UX/UI design, and acceptance criteria. Unifying our approach across different products made cross-team collaboration easier.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>A design system adapted to our needs:</strong> we created a set of reusable components and patterns and defined rules for the usage and management of the design system. It allowed us to make different tools and systems converge into a cohesive experience for users.</p></li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Veepee',
  mounted () {
    window.scrollTo(0, 0)
  },
  data: function () {
    return {
      expanded: false
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
    }
  }
}
</script>