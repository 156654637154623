<template>
  <div class="bf-pf">
    <router-link :to="{ name: 'Sitio', hash: 'Portfolio' }" class="bf-pf-close">
      <img src="../assets/close.svg" alt="Close">
    </router-link>
    <div class="bf-pf-header">
      <img src="../assets/cycloid.png" alt="Cycloid">
      <div>
        <h1>Cycloid</h1>
        <span>Product Design for a fast growing deep-tech startup.</span>
      </div>
    </div>
    <div class="bf-pf-body">
      <section class="bf-pf-context">

        <p>Cycloid is a platform focused on cloud technologies. They aim to provide a cohesive approach for organizations to adopt the cloud, covering governance, private/public cloud management, and more.</p> 
        <p>They rely on a top-notch internal DevOps team and some well-trusted open-source projects and best practices.</p>
        <br>
        <img src="../assets/cycloid/cycloid1.png" alt="The Place">
      </section>
      <section>
        <h1>Challenges</h1>
        <p>When I arrived, Cycloid already had a nice set of features and customers but lacked a Product approach. We worked to boost the platform to the next level in terms of UX and ease of usage.</p>
        <ul>
          <li><span class="simbolo">🔥</span><p><strong>Lack of visual identity:</strong> the platform was created using a Vue template with almost no modifications. It worked but seemed more like an internal tool than a SaaS.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Highly technical context:</strong> our activity relied on well-known Open Source projects. As a designer, I needed a certain knowledge of those to propose realistic solutions.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Need to reduce complexity:</strong> cloud usage is complex, but we wanted the platform to be accessible to all kinds of profiles, from DevOps to managers with no technical knowledge.</p></li>
          <li><span class="simbolo">🔥</span><p><strong>Not much user-centric:</strong> the platform was developed with insights from the internal DevOps team and customers' opinions, but this was not done systematically.</p></li>
        </ul>
      </section>
      <section>
        <h1>Approach</h1>
        
        <ul class="topal">
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Discovery Phase :</strong></p>
              <p>Most of the research I performed at Cycloid was internal, as this was a key to our philosophy : our DevOps built the product they would like to use, and we managed our customers using the same platform we were selling.</p>
              <p>During my first months at Cycloid, I got a fair understanding of the Open Source technologies we used, their value, and some best practices, which made me a good candidate to help our Marketing and Human Resources teams understand our product, for communication and recruiting.</p>
              <img src="../assets/cycloid/cy1.png" alt="">
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Design thinking:</strong></p>
              <p>Cycloid stacks are cloud configurations, including infrastructure as code (IaC), provisioning, and monitoring. They bring an automated life cycle, populated environments, and automated tests deployed on different cloud providers with a few clicks.</p>
              <p>After several workshops with our CTO and technical stakeholders, we decided to work on features to provide easy visualization tools. Those would allow users to keep the big picture simple, without hiding all the underlying complexity of IaC.</p>
              <p>We also wanted users to be able to define and configure their stacks with forms and provide previsualization of the generated code.</p>
              <img src="../assets/cycloid/cy2.png" alt="">
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>Product design:</strong></p>
              <p>Working with internal decision-makers lead us to identify a coherent approach to evolve the platform. It declined in two epics aimed to become the backbone of our experience.</p>
              <br>
              <p><strong>StackCraft</strong></p>
              <p>A visual tool and engine allowing CRUD infrastructures. It allows drag and drop of different cloud resources, configurable directly in the interface.</p>
              <p>We used focus groups with our in-house DevOps to gather relevant insights from their work and collaboration with our customers. It allowed us to identify the most relevant features from the Open Source stack we used, which we prioritized in our roadmap.</p>
              <p>I worked with our UI to define an extensible set of components and organisms allowing us to cover the basics of StackCraft, and to extend it to other usages in the future.</p>
              <p>We used Wizzard of Oz, pairing with DevOps to conduct tests with users early. It helped us identify the best approaches for the beta release.</p>
              <img src="../assets/cycloid/cy3.png" alt="">
              <br>
              <li class="recipe">
            <span class="simbolo"> </span>
            <div>
              <div class="bf-pf-button-block">
                💡💡💡
                <button class="bf-pf-button" 
                @click="expand()">{{expanded ? 'Hide':'View'}} Wizard of Ozz recipe </button>
                💡💡💡
              </div>
              <div class="bf-recipe-content" :class="{ expanded: expanded }">
                <h3>🔪 Ingredients</h3>
                <ul>
                  <li>- A talented and quick wizard behind the scenes</li>
                  <li>- Agreement on what we can expect from the wizard</li>
                  <li>- Clearly defined goal(s)</li>
                  <li>- A script giving participants choices, but not much</li>
                  <li>- Relevant questions to fill waiting times</li>
                </ul>
                <p>Mocking behavior with a prototype needs careful preparation. It needs to feel true to participants to a certain degree. A safe way to achieve this is by defining a restricted path of choices for participants and guiding them through it. The wizard needs to be able to respond to these interactions in a reasonable time. A script that defines pickings on the path and gives time for the wizard to prepare outcomes makes things quicker when testing.</p>
                <p>The experience itself has to be fluid too. Test things in a real environment with some wiring under the hood is best, but doing it more artisanally, combining different tabs or tools is fine too. If the user can perform actions realistically and have adequate feedback, the experience will feel right. Sessions need careful planning and should consider the participant's knowledge and skills.</p>
                <br>
                <h3>🍳 Cooking it</h3>
                <p>I start by pitching the participant about the session. It is a good idea to explain that we are mocking things, but no need to give all the details. I usually explain that some things will work as expected, while others may not, and provide more or less detail depending on the participant's knowledge. I also give clear instructions on the exercise: I usually agree with participants to state actions before performing them. It makes room for redirection, helps avoid unwanted behaviors, and lets me cover gaps the mock doesn't support ("OK, I get the point, let's imagine you already did that, and now the situation is this").</p>
                <p>During the exercise, I ask the participant to make a choice, then comment or ask questions about it. Doing so has several benefits: On one hand, it gives time to the wizard to build the outcome. On the other, it gets raw unbiased input from the participant before knowing the real thing, allowing for discussing both approaches if different. We keep constant communication with the wizard via chat, and it is OK to stop the exercise for a moment to discuss things with the wizard if needed.</p>
                <p>At the end of the exercise, I always invite the wizard to pop in and join the final conversation with the participant. It is always fun for participants to get to know the brains behind the system and to comment on moments that went wrong or challenging. As the wizard needs to be familiar with the system tested, he often has relevant questions or comments I may have missed during the exercise. I always try to end by summing up my conclusions and trying to get an agreed outcome with both the participant and the wizard.</p>
                <br>
                <h3>🧁 Mise en place</h3>
                <p>Although Wizard of Oz may help identify interface and usability bugs, it is better suited to test the big picture. Does the participant get the metaphors right? Can she anticipate the system's outputs after some initial interactions with it? </p>
                <p>It is nice to build some KPIs from the study relating to the main goals, then complement them with less deterministic stuff: good ideas, unexpected behaviors, or things that surprised the participants, the wizard, or myself. Last, showing relevant moments of the exercise helps stakeholders get into the user's shoes, generate empathy, and help build a shared story around the feature or system.</p>
              </div>
            </div>
              </li>
              <p><strong>Stackforms</strong></p>
              <p>A stack allows Cycloid users to define, configure, and deploy cloud infrastructure and the rules that govern it within minutes.</p>
              <p>Stackforms is a graphical tool to configure those stacks easily. We defined a visual language for the different types of values available, documented their contracts, and implemented UI elements.</p>
              <p>The challenge was to find the balance between flexibility (several approaches for the same problem), and the ease of usage in terms of UX/UI for a stack configuration.</p>
              <p>Our approach was to add constraints to Cycloid stacks in the parts needing expert DevOps knowledge. It allowed us to keep forms definitions well structured and generate UI on the fly. We kept the possibility of detaching stacks for expert usage.</p>
              <img src="../assets/cycloid/cy4.png" alt="">
            </div>
          </li>
          <li>
            <span class="simbolo">🎯</span>
            <div>
              <p><strong>DesignOps:</strong></p>
              <p>We put a lot of effort into designing elements that provide as much information as an IDE through color conventions, standards, or interaction patterns while improving the aspects in which code is less intuitive.</p>
              <p>As a small design team, we learned a lot about Design Systems. We set a lean versioning system for our components that allowed us to scale and trace design debt efficiently to tackle it whenever possible.</p>
              <img src="../assets/cycloid/cy5.png" alt="">
            </div>
          </li>
        </ul>
      </section>
      <section>
        <h1>Outcomes</h1>
        <ul>
          <li><span class="simbolo">❤️</span><p><strong>A strategy to develop the product:</strong> we defined a coherent approach to the different layers Cycloid platform covered. It resulted in a coherent experience UX/UI side and gave the product a recognizable story to be told.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>Shareable understanding of our activity:</strong> in such a technical context, being able to explain and vulgarize our activity resulted in a better understanding of our activity for non-technical roles. This improved our capabilities in terms of hiring and marketing among others.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>A UX/UI fitting our product story:</strong> our goal was to simplify cloud management with no restrictions, so we put a lot of effort into creating streamlined user flows, while keeping all the power features and shortcuts a techie would love.</p></li>
          <li><span class="simbolo">❤️</span><p><strong>A documented visual language:</strong> defining components and patterns, and documenting their capabilities, constraints, and limitations, helped us promote a common way of addressing problems. It got dev teams more involved in features definition and made us move faster, as we were able to tackle similar problems with a well-defined and known approach.</p></li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cycloid',
  mounted () {
window.scrollTo(0, 0)
  },
  data: function () {
    return {
      expanded: false
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
    }
  }
}
</script>