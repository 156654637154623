<template>
  <div class="bf-language">
    <div class="bf-language-img-cnt">
      <img
        v-show="$root.$i18n.locale === 'es' && selected != null" 
        src="../assets/eu_es.png" 
        alt="Hey, I'm Breixo">
      <img
        v-show="selected === null" 
        src="../assets/eu_small.png" 
        alt="Hey, I'm Breixo">
        <img
        v-show="$root.$i18n.locale === 'fr' && selected != null" 
        src="../assets/eu_fr.png" 
        alt="Hey, I'm Breixo">
      <img
        v-show="$root.$i18n.locale === 'gl' && selected != null" 
        src="../assets/eu_gl.png" 
        alt="Hey, I'm Breixo">
      <img
        v-show="$root.$i18n.locale === 'en' && selected != null" 
        src="../assets/eu_en.png" 
        alt="Hey, I'm Breixo">
      <img
        v-show="$root.$i18n.locale === 'zh' && selected != null" 
        src="../assets/eu_zh.png" 
        alt="Hey, I'm Breixo">
      
      <b :class="{ selected: !selected }">I love learning new lenguages 🤓</b>
      <b 
        :class="{ selected: selected }"
        v-html="$t('sentence')"></b>
      <!--<p
        :class="{ selected: selected, italics: true }" 
        v-html="$t('level')"></p>-->
      <div>
        <button 
          @click="changeLocale('en')">🍟</button>
        <button 
          @click="changeLocale('fr')">🥐</button>
        <button 
          @click="changeLocale('gl')">🐙</button>
        <button 
          @click="changeLocale('es')">🥘</button>
        <button 
          @click="changeLocale('zh')">🥟</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Language',
  data: function () {
    return {
      hovered: null,
      selected: null,
    }
  },
  created: function () {
    this.$root.$i18n.locale = null  
  },
  methods: {
    changeLocale(lc) {
      this.$root.$i18n.locale = lc
      this.selected= lc
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'responsive';

b {
  font-size: 22px;
  margin-top: 0;
}

.bf-language {
  display: flex;
  padding-top: 32px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 6em;


  @include mq($from: mobile, $until: tablet) {
    flex-direction: column;
    position: static;
    overflow-y: scroll;
    display: block;
  }
}

.bf-language-img-cnt  {
  img {
    max-width: 160px;
    max-height: auto;
  }

  b, h2 {
    display: none;
    &.selected {
      display: block;
      margin-bottom: 22px;
    }
  }

  h2 {
    margin-bottom: 48px;
  }
  
  .italics {
    font-style: italic;
  }

  @include mq($from: mobile, $until: tablet) {
    min-height: 100px;

    img {
      max-width: 45vw;
    }
  }
}


button {
  display: inline-block;
  text-align: center;
  padding: 8px;
  margin: 0 12px;
  background: Radial-gradient(ellipse at bottom right, #1CBCED, #1B81A1);
  color: wheat;
  border-radius: 6px;
  font-size: 32px;
	cursor: pointer;

  &:hover{
    background: Radial-gradient(ellipse at bottom right, #1CBCED 62%, #1B81A1);
  }

  @include mq($from: mobile, $until: tablet) {
    margin: 0 8px;
    font-size: 24px;
  }
}
</style>

<i18n>
  {
    "gl": {
      "sentence": "“Canta claro! que che dixo?”",
      "level": "Nível: máis nativo que votar á dereita."
    },
    "es": {
      "sentence": "“Si me queréis, Idxe”",
      "level": "Nivel: a veces no uso tiempos compuestos."      
    },
    "fr": {
      "sentence": "“Le design d'interaction m'a tuer”",
      "level": "Niveau: tout sauf les formules de politesse."
    },
    "en": {
      "sentence": "“I'll be a rock 'n' rollin' UX for you”",
      "level": "Level: I can speak English way better than Manuel from BCN."
    },
    "zh": {
      "sentence": "“我学汉语，我喜欢中国菜”",
      "level": "Level: I can order bāozi and a beer comfortably."
    }
  }
  </i18n>