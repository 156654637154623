<template>
  <div id="app">
    <div class="background">
      <span></span>
      <span></span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      showLang: true,
    };
  },
  methods: {
    hideLang() {
      this.showLang = false;
    },
  },
};
</script>

<style lang="scss">
@import 'components/responsive';

::selection {
  background: #1B81A1;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: wheat;
  color: #ffedca;

  font-family: "PT Sans Narrow", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong, a {
  font-family: "PT Serif Caption", sans-serif;
}



h3 {
  font-size: 22px;
  margin-top: 28px;
}

p {
  margin: 1em 0;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  scroll-timeline: 2.5s;
}

.background {
  width: 100vw;
  height: 100vh;
  background: #3e1e68;
  background: linear-gradient(to left, #0cd0f3, #dfd18e);
  background: Linear-gradient(to left, #184d5d, #270c59);
  background: linear-gradient(to right, #fc5e5c, #fe944a);
  background: radial-gradient(ellipse at bottom, #2c4e6d, #371f57);
  background: Linear-gradient(to left, #45031b, #05513f);
  background: Radial-gradient(ellipse at bottom right, #61152a, #0e0512);


  position: fixed;
  z-index: -1;
}

$particleSize: 80vmin;
$animationDuration: 1200s;
$amount: 70;
.background span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (#0e0512);
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 20s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

a {
  color: #1B81A1;
  font-weight: 700;
  text-decoration: none;
  background-image: Radial-gradient(ellipse at bottom right, rgba(#1CBCED, 0.3), rgba(#1B81A1, 0.3));
  background-size: 0;
  background-repeat: no-repeat;
  display: inline;
  transition: 0.15s cubic-bezier(0.4, 0, 1, 1);
  
  &:hover {
    background-size: 100%;
    color: wheat;
  }
}

ul {
  @include mq($from: mobile, $until: tablet) {
    padding-left: 24px;
  }
}

li {
    padding: 8px 0;
    display: flex;
    justify-content: left;
    align-items: center;

    .topal &   {
      align-items: baseline;
      margin-bottom: 48px;
    }
}

img {
  border-radius: 4px;
}

.bf-pf-button {
  font-family: 'PT Sans Narrow';
  display: inline-block;
  text-align: center;
  padding: 12px 24px;
  margin: 0 12px;
  background: Radial-gradient(ellipse at bottom right, #1CBCED, #1B81A1);
  color: wheat;
  border-radius: 6px;
  font-size: 32px;
	cursor: pointer;

  &:hover{
    background: Radial-gradient(ellipse at bottom right, #1CBCED 62%, #1B81A1);
  }

  @include mq($from: mobile, $until: tablet) {
    margin: 0 8px;
    font-size: 24px;
  }
}

.recipe {
margin: 48px 0;
ul {
  padding: 0;
}

  li{
    margin: 0;
  }

  .bf-pf-button-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bf-recipe-content {
    height: 0px;
    overflow: hidden;
    backdrop-filter: blur(5px);

    &.expanded {
      height: auto;
      padding: 24px 40px 32px;
      margin-top: 30px;
      border-radius: 32px;
      backdrop-filter: contrast(0.5);
    }
  }
}

.simbolo {
  font-size: 32px;
  padding-right: 20px;

  @include mq($from: mobile, $until: tablet) {
    font-size: 28px;
    padding-right: 12px;
  }
}

.bf-pf {
  max-width: 890px;
  margin: 0px auto;
  padding: 12px;
  padding-top: 48px;
  font-size: 22px;
  padding-bottom: 72px;

  @include mq($from: mobile, $until: tablet) {
    font-size: 20px;
  }
}

.bf-pf-header {
  display: flex;
  align-items: center;
  margin-bottom: 64px;

  img {
    width: 120px;
    margin-right: 32px;
    border-radius: 4px;
  }
  h1 {
    margin: 0;
  }

}

.bf-pf-body {
  width: 100%;
  h1 {
    margin-top: 96px;
  }

  img {
      max-width: 70%;
      display: block;
      margin: 56px auto 24px;

      &.fullsizeimg {
        max-width: 100%;
      }

      @include mq($from: mobile, $until: tablet) {
        max-width: 90%;
        margin: 32px 0;
      }
  }
}

.bf-pf-close {
    position: fixed;
    top: 24px;
    right: 24px;
    padding: 8px;

    &:hover {
      background-size: 0;
      color: inherit;
      transform: rotate(12deg);
    }

    img {
      width: 48px;
    }

    @include mq($from: mobile, $until: desktop-high) {
      display: none;
    }
    
      
    span {
      display: block;
      width: 32px;
      height: 4px;
      background-color: wheat;
      &:first-child {
        transform: rotate(45deg);
        transform-origin: bottom;
        &:hover {
          transform: rotate(56deg);
        }
      }
      &:last-child {
        transform: rotate(-45deg);
        transform-origin: top;
        &:hover {
          transform: rotate(-56deg);
        }
      }
    }
  }
</style>

